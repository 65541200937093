import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToMount from '../../common/ScrollToMount'
import FormContainer from './FormContainer'

import { saveOppDetails, saveIdProof, updateForm } from '../../actions/formActions'
import { toast } from 'react-toastify'
import FormCompletetionSteps from './FormCompletetionSteps'
toast.configure()
const EqualOppurtunities = ({ history }) => {
    const form = useSelector((state) => state.form)
    const { oppDetails, personalDetails } = form

    const form1 = useSelector((state) => state.userDetails)
    const { formDetails } = form1

    const formCreate = useSelector((state) => state.updateForm)
    const { success } = formCreate

    const [ethnicOrigin, setEthnicOrigin] = useState(oppDetails.ethnicOrigin)
    const [disabilities, setDisablities] = useState(oppDetails.disabilities)
    const [wheelchair, setWheelchair] = useState(oppDetails.wheelchair)
    const [firstLang, setFirstLang] = useState(oppDetails.firstLang)
    const [resident, setResident] = useState(oppDetails.resident)
    const [nationality, setNationality] = useState(oppDetails.nationality || "british")
    const [inWhichCountryDoYouLive, setInWhichCountryDoYouLive] = useState(oppDetails.inWhichCountryDoYouLive)
    const [countryOfBirth, setCountryOfBirth] = useState(oppDetails.countryOfBirth)
    const [statementScheme, setStatementScheme] = useState(oppDetails.statementScheme)
    const [nonEEACitizen, setNonEEACitizen] = useState(oppDetails.nonEEACitizen)
    const [ukStayPermission12Month, setUkStayPermission12Month] = useState(oppDetails.ukStayPermission12Month)
    const [dValue1, setValue] = useState(oppDetails.dValue1)
    const [dValue2, setValue2] = useState(oppDetails.dValue2)
    const [dValue3, setValue3] = useState(oppDetails.dValue3)
    const [dValue4, setValue4] = useState(oppDetails.dValue4)
    const [dValue5, setValue5] = useState(oppDetails.dValue5)
    const [dValue6, setValue6] = useState(oppDetails.dValue6)
    const [dValue7, setValue7] = useState(oppDetails.dValue7)
    const [dValue8, setValue8] = useState(oppDetails.dValue8)
    const [dValue9, setValue9] = useState(oppDetails.dValue9)
    const [dValue10, setValue10] = useState(oppDetails.dValue10)
    const [dValue11, setValue11] = useState(oppDetails.dValue11)
    const [dValue12, setValue12] = useState(oppDetails.dValue12)
    const [dValue13, setValue13] = useState(oppDetails.dValue13)
    const [dValue14, setValue14] = useState(oppDetails.dValue14)
    const [dValue15, setValue15] = useState(oppDetails.dValue15)
    const [dValue16, setValue16] = useState(oppDetails.dValue16)
    const [dValue17, setValue17] = useState(oppDetails.dValue17)
    const [dValue18, setValue18] = useState(oppDetails.dValue18)
    const [adhd, setAdhd] = useState(oppDetails.adhd)

    const [criminalConv, setCriminalConvictions] = useState(oppDetails.criminalConv)
    const [inCare, setInCare] = useState(oppDetails.inCare)
    const [dateYouEnteredUK, setDateYouEnteredUK] = useState(oppDetails.dateYouEnteredUK)
    const [hasVisa, setHasVisa] = useState(oppDetails.hasVisa)
    const [rightToStudy, setRightToStudy] = useState(oppDetails.rightToStudy)
    const [show, setShow] = useState(false)
    const [showFileUpload, setFileUpload] = useState(false)
    const [showFileUploadEuScheme, setshowFileUploadEuScheme] = useState(false)
    const [validated, setValidated] = useState(false)
    const [perField, setPerField] = useState()
    const [id1, setID1] = useState(null)
    const [id2, setID2] = useState(null)
    const [id3, setID3] = useState(null)
    const [id4, setID4] = useState(null)

    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const uploadImage = (file) => {
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'equaloppertunities')
        data.append('cloud_name', 'my-free-course')
        return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log('URL FIles', res.url)
                return res.url
            })
    }

    const submitHandler = (e) => {
        const form = e.currentTarget
        if (resident === "No") {
            toast.error("You havent been a resident for 3 years, so you dont Qualify")
            e.preventDefault()
            e.stopPropagation()
        } else if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
            var element = document.getElementById('Error-msg-form')
            element.classList.remove('Error-msg-form-displayNone')
            element.classList.add('Error-msg-form-display')
            document.getElementById('Error-msg-form').scrollIntoView({
                behavior: 'smooth',
            })
        } else if (disabilities === "Yes" && !dValue1 && !dValue2 && !dValue3 && !dValue4 && !dValue5 && !dValue6 && !dValue7 && !dValue8 && !dValue9 && !dValue10 && !dValue11 && !dValue12 && !dValue13 && !dValue14 && !dValue15 && !dValue16 && !dValue17 && !dValue18) {
            e.preventDefault()
            e.stopPropagation()
            toast.error("Please select atleast one")
            setError(true)
        } else {
            e.preventDefault()
            if (disabilities === 'Yes') {
                let images = [id1, id2, id3, id4]

                let promises = []
                images.forEach((image) => promises.push(uploadImage(image)))
                Promise.all([...promises])
                    .then((values) => {
                        dispatch(
                            saveOppDetails({
                                ethnicOrigin,
                                disabilities,
                                wheelchair,
                                firstLang,
                                resident,
                                nationality,
                                inWhichCountryDoYouLive,
                                statementScheme,
                                nonEEACitizen,
                                criminalConv,
                                perField,
                                dValue1,
                                dValue2,
                                dValue3,
                                dValue4,
                                dValue5,
                                dValue6,
                                dValue7,
                                dValue8,
                                dValue9,
                                dValue10,
                                dValue11,
                                dValue12,
                                dValue13,
                                dValue14,
                                dValue15,
                                dValue16,
                                dValue17,
                                dValue18,
                                attachment1: values[0] || '',
                                attachment2: values[1] || '',
                                attachment3: values[2] || '',
                                attachment4: values[3] || '',
                                countryOfBirth,
                                ukStayPermission12Month,
                                adhd: adhd,
                                inCare: inCare,
                                dateYouEnteredUK: dateYouEnteredUK,
                                hasVisa: hasVisa,
                                rightToStudy: rightToStudy
                            })
                        )
                    })
                    .catch((err) => {
                        console.log(err)
                    })

                const data = new FormData()
                history.push('/declaration')
            } else {
                let images = [id1, id2, id3, id4]
                let promises = []
                images.forEach((image) => promises.push(uploadImage(image)))
                Promise.all([...promises])
                    .then((values) => {
                        console.log('Values', values)
                        dispatch(
                            saveOppDetails({
                                ethnicOrigin,
                                disabilities,
                                wheelchair,
                                firstLang,
                                perField,
                                resident,
                                nationality,
                                inWhichCountryDoYouLive,
                                statementScheme,
                                nonEEACitizen,
                                criminalConv,
                                attachment1: values[0] || '',
                                attachment2: values[1] || '',
                                attachment3: values[2] || '',
                                attachment4: values[3] || '',
                                countryOfBirth,
                                ukStayPermission12Month,
                                inCare: inCare,
                                dateYouEnteredUK: dateYouEnteredUK,
                                hasVisa: hasVisa,
                                rightToStudy: rightToStudy
                            })
                        )
                    })
                    .catch((err) => {
                        console.log(err)
                    })

                const data = new FormData()
                history.push('/declaration')
            }
        }
        setValidated(true)
    }
    const handleChange = (e) => {
        setDisablities(e.target.value)
        if (e.target.value === 'Yes') {
            setShow(true)
        } else {
            setShow(false)
        }
    }
    const handleChange1 = (e) => {
        setNonEEACitizen(e.target.value)
        if (e.target.value === 'Yes') {
            setFileUpload(true)
        } else {
            setFileUpload(false)
        }
    }
    const handleChange2 = (e) => {
        setStatementScheme(e.target.value)
        if (e.target.value === 'Yes') {
            setshowFileUploadEuScheme(true)
        } else {
            setshowFileUploadEuScheme(false)
        }
    }
    useEffect(() => {
        if (success) {
            toast.success('Form Updated Successfully')
        }
    }, [success])

    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <FormContainer>
                <ScrollToMount />
                <FormCompletetionSteps step1 step2 step3 step4 step5 step6 />

                <h1>Step 6: Equal Opportunities</h1>
                <div
                    className='text-center mb-5 Error-msg-form Error-msg-form-displayNone'
                    id='Error-msg-form'
                >
                    <p>Please fix one or more fields.</p>
                </div>

                <Form noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Group controlId='ethnicOrigin'>
                        <Form.Label>What is your Ethnic Origin?</Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={ethnicOrigin}
                            onChange={(e) => setEthnicOrigin(e.target.value)}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='English/Welsh/Scottish/Northern Irish/British'>
                                English/Welsh/Scottish/Northern Irish/British
                            </option>
                            <option value='Irish'>Irish</option>
                            <option value='Gypsy or Irish traveller'>
                                Gypsy or Irish traveller
                            </option>
                            <option value='Any other white background'>
                                Any other white background
                            </option>
                            <option value='White and Black Carribean'>
                                White and Black Carribean
                            </option>
                            <option value='White and Black African'>White and Black African</option>
                            <option value='White and Asian'>White and Asian</option>
                            <option value='Any other mixed/multiple ethnic background'>
                                Any other mixed/multiple ethnic background
                            </option>
                            <option value='Indian'>Indian</option>
                            <option value='Pakistani'>Pakistani</option>
                            <option value='Bangladeshi'>Bangladeshi</option>
                            <option value='Chinese'>Chinese</option>
                            <option value='Any other Asian background'>
                                Any other Asian background
                            </option>
                            <option value='African'>African</option>
                            <option value='Caribbean'>Caribbean</option>
                            <option value='Any other Black/African/Caribbean'>
                                Any other Black/African/Caribbean
                            </option>
                            <option value='Arab'>Arab</option>
                            <option value='Any other ethnic group'>Any other ethnic group</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='disabilities'>
                        <Form.Label>
                            Do you have any disabilities or learning difficulties?
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={disabilities}
                            onChange={handleChange}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {show && (
                        <>
                            <br />{error && <span style={{ color: 'red' }}>Please check at least one option.</span>}
                            <Form.Group controlId=''>
                                <Form.Label>Select those which apply</Form.Label>
                                <br />

                                <Form.Check
                                    type='checkbox'
                                    label='Visual Impairment'
                                    name='dValue1'
                                    value='dValue1'
                                    onChange={(e) => {
                                        setValue(e.target.value)
                                    }}
                                    className='mr-2'
                                ></Form.Check>

                                <Form.Check
                                    type='checkbox'
                                    label='Hearing Impairment'
                                    name='dValue2'
                                    value='dValue2'
                                    onChange={(e) => setValue2(e.target.value)}
                                    className='mr-2'
                                ></Form.Check>

                                <Form.Check
                                    type='checkbox'
                                    label='Disability affecting mobility'
                                    name='dValue3'
                                    value='dValue3'
                                    onChange={(e) => setValue3(e.target.value)}
                                    className='mr-2'
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Profound complex disabilities'
                                    name='dValue4'
                                    value='dValue4'
                                    className='mr-2'
                                    onChange={(e) => setValue4(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Social and emotional difficulties'
                                    name='dValue5'
                                    value='dValue5'
                                    className='mr-2'
                                    onChange={(e) => setValue5(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Mental Health Difficulties'
                                    name='dValue6'
                                    value='dValue6'
                                    className='mr-2'
                                    onChange={(e) => setValue6(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Moderate Learning Difficulties'
                                    name='dValue7'
                                    value='dValue7'
                                    className='mr-2'
                                    onChange={(e) => setValue7(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Severe Learning Difficulties'
                                    name='dValue8'
                                    value='dValue8'
                                    className='mr-2'
                                    onChange={(e) => setValue8(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Dyslexia'
                                    name='dValue9'
                                    value='dValue9'
                                    className='mr-2'
                                    onChange={(e) => setValue9(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Dyscalculia'
                                    name='dValue10'
                                    value='dValue10'
                                    className='mr-2'
                                    onChange={(e) => setValue10(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Autism Spectrum Disorder'
                                    name='dValue11'
                                    value='dValue11'
                                    className='mr-2'
                                    onChange={(e) => setValue11(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label="ADHD/ADD"
                                    name='adhd'
                                    value='adhd'
                                    className='mr-2'
                                    onChange={(e) => setAdhd(!adhd)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Temporary disability after illness or accident'
                                    name='dValue13'
                                    value='dValue13'
                                    className='mr-2'
                                    onChange={(e) => setValue13(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Speech, language and communication needs'
                                    name='dValue14'
                                    value='dValue14'
                                    className='mr-2'
                                    onChange={(e) => setValue14(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Other physical disabilities'
                                    name='dValue15'
                                    value='dValue15'
                                    className='mr-2'
                                    onChange={(e) => setValue15(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Medical condition e.g. epilepsy, asthma, diabetes'
                                    name='dValue16'
                                    value='dValue16'
                                    className='mr-2'
                                    onChange={(e) => setValue16(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Other learning difficulties'
                                    name='dValue17'
                                    value='dValue17'
                                    className='mr-2'
                                    onChange={(e) => setValue17(e.target.value)}
                                ></Form.Check>
                                <Form.Check
                                    type='checkbox'
                                    label='Other disabilities'
                                    name='dValue18'
                                    value='dValue18'
                                    className='mr-2'
                                    onChange={(e) => setValue18(e.target.value)}
                                ></Form.Check>
                            </Form.Group>
                        </>
                    )}
                    <Form.Group controlId='wheelchair'>
                        <Form.Label>Are you a wheelchair user? </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={wheelchair}
                            onChange={(e) => setWheelchair(e.target.value)}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='firstLang'>
                        <Form.Label>is English your first language?</Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={firstLang}
                            onChange={(e) => setFirstLang(e.target.value)}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='nationality'>
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                        >
                            <option selected value="">Please Select One</option>
                            <option value="british">British</option>
                            <option value="British National (Overseas)">British National (Overseas)</option>
                            <option value="afghan">Afghan</option>
                            <option value="albanian">Albanian</option>
                            <option value="algerian">Algerian</option>
                            <option value="american">American</option>
                            <option value="andorran">Andorran</option>
                            <option value="angolan">Angolan</option>
                            <option value="antiguans">Antiguans</option>
                            <option value="argentinean">Argentinean</option>
                            <option value="armenian">Armenian</option>
                            <option value="australian">Australian</option>
                            <option value="austrian">Austrian</option>
                            <option value="azerbaijani">Azerbaijani</option>
                            <option value="bahamian">Bahamian</option>
                            <option value="bahraini">Bahraini</option>
                            <option value="bangladeshi">Bangladeshi</option>
                            <option value="barbadian">Barbadian</option>
                            <option value="barbudans">Barbudans</option>
                            <option value="batswana">Batswana</option>
                            <option value="belarusian">Belarusian</option>
                            <option value="belgian">Belgian</option>
                            <option value="belizean">Belizean</option>
                            <option value="beninese">Beninese</option>
                            <option value="bhutanese">Bhutanese</option>
                            <option value="bolivian">Bolivian</option>
                            <option value="bosnian">Bosnian</option>
                            <option value="brazilian">Brazilian</option>
                            <option value="british">British</option>
                            <option value="bruneian">Bruneian</option>
                            <option value="bulgarian">Bulgarian</option>
                            <option value="burkinabe">Burkinabe</option>
                            <option value="burmese">Burmese</option>
                            <option value="burundian">Burundian</option>
                            <option value="cambodian">Cambodian</option>
                            <option value="cameroonian">Cameroonian</option>
                            <option value="canadian">Canadian</option>
                            <option value="cape verdean">Cape Verdean</option>
                            <option value="central african">Central African</option>
                            <option value="chadian">Chadian</option>
                            <option value="chilean">Chilean</option>
                            <option value="chinese">Chinese</option>
                            <option value="colombian">Colombian</option>
                            <option value="comoran">Comoran</option>
                            <option value="congolese">Congolese</option>
                            <option value="costa rican">Costa Rican</option>
                            <option value="croatian">Croatian</option>
                            <option value="cuban">Cuban</option>
                            <option value="cypriot">Cypriot</option>
                            <option value="czech">Czech</option>
                            <option value="danish">Danish</option>
                            <option value="djibouti">Djibouti</option>
                            <option value="dominican">Dominican</option>
                            <option value="dutch">Dutch</option>
                            <option value="east timorese">East Timorese</option>
                            <option value="ecuadorean">Ecuadorean</option>
                            <option value="egyptian">Egyptian</option>
                            <option value="emirian">Emirian</option>
                            <option value="equatorial guinean">Equatorial Guinean</option>
                            <option value="eritrean">Eritrean</option>
                            <option value="estonian">Estonian</option>
                            <option value="ethiopian">Ethiopian</option>
                            <option value="fijian">Fijian</option>
                            <option value="filipino">Filipino</option>
                            <option value="finnish">Finnish</option>
                            <option value="french">French</option>
                            <option value="gabonese">Gabonese</option>
                            <option value="gambian">Gambian</option>
                            <option value="georgian">Georgian</option>
                            <option value="german">German</option>
                            <option value="ghanaian">Ghanaian</option>
                            <option value="greek">Greek</option>
                            <option value="grenadian">Grenadian</option>
                            <option value="guatemalan">Guatemalan</option>
                            <option value="guinea-bissauan">Guinea-Bissauan</option>
                            <option value="guinean">Guinean</option>
                            <option value="guyanese">Guyanese</option>
                            <option value="haitian">Haitian</option>
                            <option value="herzegovinian">Herzegovinian</option>
                            <option value="honduran">Honduran</option>
                            <option value="hungarian">Hungarian</option>
                            <option value="icelander">Icelander</option>
                            <option value="indian">Indian</option>
                            <option value="indonesian">Indonesian</option>
                            <option value="iranian">Iranian</option>
                            <option value="iraqi">Iraqi</option>
                            <option value="irish">Irish</option>
                            <option value="israeli">Israeli</option>
                            <option value="italian">Italian</option>
                            <option value="ivorian">Ivorian</option>
                            <option value="jamaican">Jamaican</option>
                            <option value="japanese">Japanese</option>
                            <option value="jordanian">Jordanian</option>
                            <option value="kazakhstani">Kazakhstani</option>
                            <option value="kenyan">Kenyan</option>
                            <option value="kittian and nevisian">Kittian and Nevisian</option>
                            <option value="kuwaiti">Kuwaiti</option>
                            <option value="kyrgyz">Kyrgyz</option>
                            <option value="laotian">Laotian</option>
                            <option value="latvian">Latvian</option>
                            <option value="lebanese">Lebanese</option>
                            <option value="liberian">Liberian</option>
                            <option value="libyan">Libyan</option>
                            <option value="liechtensteiner">Liechtensteiner</option>
                            <option value="lithuanian">Lithuanian</option>
                            <option value="luxembourger">Luxembourger</option>
                            <option value="macedonian">Macedonian</option>
                            <option value="malagasy">Malagasy</option>
                            <option value="malawian">Malawian</option>
                            <option value="malaysian">Malaysian</option>
                            <option value="maldivan">Maldivan</option>
                            <option value="malian">Malian</option>
                            <option value="maltese">Maltese</option>
                            <option value="marshallese">Marshallese</option>
                            <option value="mauritanian">Mauritanian</option>
                            <option value="mauritian">Mauritian</option>
                            <option value="mexican">Mexican</option>
                            <option value="micronesian">Micronesian</option>
                            <option value="moldovan">Moldovan</option>
                            <option value="monacan">Monacan</option>
                            <option value="mongolian">Mongolian</option>
                            <option value="moroccan">Moroccan</option>
                            <option value="mosotho">Mosotho</option>
                            <option value="motswana">Motswana</option>
                            <option value="mozambican">Mozambican</option>
                            <option value="namibian">Namibian</option>
                            <option value="nauruan">Nauruan</option>
                            <option value="nepalese">Nepalese</option>
                            <option value="new zealander">New Zealander</option>
                            <option value="ni-vanuatu">Ni-Vanuatu</option>
                            <option value="nicaraguan">Nicaraguan</option>
                            <option value="nigeria">Nigeria</option>
                            <option value="north korean">North Korean</option>
                            <option value="northern irish">Northern Irish</option>
                            <option value="norwegian">Norwegian</option>
                            <option value="omani">Omani</option>
                            <option value="pakistani">Pakistani</option>
                            <option value="palauan">Palauan</option>
                            <option value="panamanian">Panamanian</option>
                            <option value="papua new guinean">Papua New Guinean</option>
                            <option value="paraguayan">Paraguayan</option>
                            <option value="peruvian">Peruvian</option>
                            <option value="polish">Polish</option>
                            <option value="portuguese">Portuguese</option>
                            <option value="qatari">Qatari</option>
                            <option value="romanian">Romanian</option>
                            <option value="russian">Russian</option>
                            <option value="rwandan">Rwandan</option>
                            <option value="saint lucian">Saint Lucian</option>
                            <option value="salvadoran">Salvadoran</option>
                            <option value="samoan">Samoan</option>
                            <option value="san marinese">San Marinese</option>
                            <option value="sao tomean">Sao Tomean</option>
                            <option value="saudi">Saudi</option>
                            <option value="scottish">Scottish</option>
                            <option value="senegalese">Senegalese</option>
                            <option value="serbian">Serbian</option>
                            <option value="seychellois">Seychellois</option>
                            <option value="sierra leonean">Sierra Leonean</option>
                            <option value="singaporean">Singaporean</option>
                            <option value="slovakian">Slovakian</option>
                            <option value="slovenian">Slovenian</option>
                            <option value="solomon islander">Solomon Islander</option>
                            <option value="somali">Somali</option>
                            <option value="south african">South African</option>
                            <option value="south korean">South Korean</option>
                            <option value="spanish">Spanish</option>
                            <option value="sri lankan">Sri Lankan</option>
                            <option value="sudanese">Sudanese</option>
                            <option value="surinamer">Surinamer</option>
                            <option value="swazi">Swazi</option>
                            <option value="swedish">Swedish</option>
                            <option value="swiss">Swiss</option>
                            <option value="syrian">Syrian</option>
                            <option value="taiwanese">Taiwanese</option>
                            <option value="tajik">Tajik</option>
                            <option value="tanzanian">Tanzanian</option>
                            <option value="thai">Thai</option>
                            <option value="togolese">Togolese</option>
                            <option value="tongan">Tongan</option>
                            <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                            <option value="tunisian">Tunisian</option>
                            <option value="turkish">Turkish</option>
                            <option value="tuvaluan">Tuvaluan</option>
                            <option value="ugandan">Ugandan</option>
                            <option value="ukrainian">Ukrainian</option>
                            <option value="uruguayan">Uruguayan</option>
                            <option value="uzbekistani">Uzbekistani</option>
                            <option value="venezuelan">Venezuelan</option>
                            <option value="vietnamese">Vietnamese</option>
                            <option value="Vincentian">Vincentian</option>
                            <option value="welsh">Welsh</option>
                            <option value="yemenite">Yemenite</option>
                            <option value="zambian">Zambian</option>
                            <option value="zimbabwean">Zimbabwean</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(localStorage.getItem('inWhichCountryDoYouLive') === "true") && <>
                        <Form.Group>
                            <Form.Label>In which country do you normally live?</Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={inWhichCountryDoYouLive}
                                onChange={(e) => setInWhichCountryDoYouLive(e.target.value)}
                            >
                                <option selected value="">Please Select One</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Aland Islands">Åland Islands</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
                                <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                <option value="Brunei Darussalam">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo - Brazzaville</option>
                                <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curacao">Curaçao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czechia</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="England">England</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Territories">French Southern Territories</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
                                <option value="Holy See (Vatican City State)">Vatican City</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran, Islamic Republic of">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea, Democratic People's Republic of">North Korea</option>
                                <option value="Korea, Republic of">South Korea</option>
                                <option value="Kosovo">Kosovo</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libyan Arab Jamahiriya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macao">Macao</option>
                                <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia, Federated States of">Micronesia</option>
                                <option value="Moldova, Republic of">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar (Burma)</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands Antilles">Curaçao</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestinian Territory, Occupied">Palestine</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn Islands</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Réunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russian Federation">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Barthelemy">St. Barthélemy</option>
                                <option value="Saint Helena">St. Helena</option>
                                <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
                                <option value="Saint Lucia">St. Lucia</option>
                                <option value="Saint Martin">St. Martin</option>
                                <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
                                <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Scotland">Scotland</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Serbia and Montenegro">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Sint Maarten">Sint Maarten</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
                                <option value="South Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
                                <option value="Swaziland">Eswatini</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syrian Arab Republic">Syria</option>
                                <option value="Taiwan, Province of China">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania, United Republic of">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Viet Nam">Vietnam</option>
                                <option value="Wales">Wales</option>
                                <option value="Virgin Islands, British">British Virgin Islands</option>
                                <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
                                <option value="Wallis and Futuna">Wallis & Futuna</option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>}
                    {(localStorage.getItem('countryOfBirth') === "true") && <>
                        <Form.Group>
                            <Form.Label>Country of Birth</Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={countryOfBirth}
                                onChange={(e) => setCountryOfBirth(e.target.value)}
                            >
                                <option selected value="">Please Select One</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Aland Islands">Åland Islands</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antarctica">Antarctica</option>
                                <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
                                <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                <option value="Brunei Darussalam">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo - Brazzaville</option>
                                <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curacao">Curaçao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czechia</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="England">England</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Territories">French Southern Territories</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernsey">Guernsey</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
                                <option value="Holy See (Vatican City State)">Vatican City</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran, Islamic Republic of">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea, Democratic People's Republic of">North Korea</option>
                                <option value="Korea, Republic of">South Korea</option>
                                <option value="Kosovo">Kosovo</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao People's Democratic Republic">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libyan Arab Jamahiriya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macao">Macao</option>
                                <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia, Federated States of">Micronesia</option>
                                <option value="Moldova, Republic of">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar (Burma)</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands Antilles">Curaçao</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestinian Territory, Occupied">Palestine</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn Islands</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Réunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russian Federation">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Barthelemy">St. Barthélemy</option>
                                <option value="Saint Helena">St. Helena</option>
                                <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
                                <option value="Saint Lucia">St. Lucia</option>
                                <option value="Saint Martin">St. Martin</option>
                                <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
                                <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Scotland">Scotland</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Serbia and Montenegro">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Sint Maarten">Sint Maarten</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
                                <option value="South Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
                                <option value="Swaziland">Eswatini</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syrian Arab Republic">Syria</option>
                                <option value="Taiwan, Province of China">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania, United Republic of">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Viet Nam">Vietnam</option>
                                <option value="Wales">Wales</option>
                                <option value="Virgin Islands, British">British Virgin Islands</option>
                                <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
                                <option value="Wallis and Futuna">Wallis & Futuna</option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>}
                    <Form.Group controlId='resident'>
                        <Form.Label>
                            Have you been resident in the UK, EU or EEA for the last 3 years?
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={resident}
                            onChange={(e) => setResident(e.target.value)}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='statementScheme'>
                        <Form.Label>
                            Have you obtained settled status under then EU Settlement Scheme?
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={statementScheme}
                            onChange={handleChange2}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(showFileUploadEuScheme) &&
                        <>
                            <Form.Label className="form-notice">
                                Providing proof of settled status for the last 3 years is a mandatory requirement in order to access funding. If this is not provided, you will be deemed ineligible for funding and not permitted to access your chosen course.
                                <br /><br />Please provide one of the below proof documents. Acceptable proof documents are:
                                <br />
                                1- Picture of your residence card (BRP Card)
                                <br />
                                2- Screenshot of your ‘share code’ from the government website below.
                                <br /><br /><a href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>
                                <br /><br /><b>You will also need to provide proof of address dated within the last 3 months. We accept Utility Bills (gas, water, electricity or phone) or Bank Letter that clearly shows your name, address, issue date and service provider.</b>

                            </Form.Label>
                            <Form.Group controlId='id3'>
                                <Form.Label>
                                    <b>Proof of residency status</b>
                                </Form.Label>
                                <Form.Control
                                    type='file'
                                    accept='image/*,application/pdf'
                                    required
                                    onChange={(e) => setID3(e.target.files[0])}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='id4'>
                                <Form.Label>
                                    <b>Proof of address dated within last 3 months</b>
                                </Form.Label>
                                <Form.Control
                                    type='file'
                                    accept='image/*,application/pdf'
                                    required
                                    onChange={(e) => setID4(e.target.files[0])}
                                ></Form.Control>
                            </Form.Group>

                        </>
                    }
                    <Form.Group controlId='nonEEACitizen'>
                        <Form.Label>
                            Are you a non EEA citizen with permission from the UK government to live
                            in the UK?
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={nonEEACitizen}
                            onChange={handleChange1}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(nationality !== 'british') && <Form.Group controlId='nonEEACitizen'>
                        <Form.Label>
                            Do you currently have permission to stay in the UK for at least 12 months?
                        </Form.Label>
                        <Form.Control
                            as='select'
                            required
                            value={ukStayPermission12Month}
                            onChange={(e) => setUkStayPermission12Month(e.target.value)}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>}

                    {showFileUpload && (
                        <>
                            <Form.Group controlId='perField'>
                                <Form.Label>
                                    What immigration permissions do you currently hold in the UK?
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={perField}
                                    onChange={(e) => setPerField(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Asylum Seeker'>Asylum Seeker</option>
                                    <option value='Indefinite Leave'>Indefinite Leave</option>
                                    <option value='Exceptional / Discretionary Leave'>
                                        Exceptional / Discretionary Leave
                                    </option>
                                    <option value='Humanitarian Protection'>
                                        Humanitarian Protection
                                    </option>
                                    <option value='Refugee'>Refugee</option>
                                    <option value='Family Member of EU citizen'>
                                        Family Member of EU citizen
                                    </option>
                                    <option value='British/EU/EEA/Citizen'>
                                        British/EU/EEA/Citizen{' '}
                                    </option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='form-notice'>
                                <Form.Label>
                                    Providing proof of your residency status for the last 3 years is a mandatory requirement in order to access funding. If this is not provided, you will be deemed ineligible for funding and not permitted to access your chosen course.
                                    <br /><br />Please provide one of the below proof documents. Acceptable proof documents are:
                                    <br />
                                    1- Picture of your residence card (BRP Card)
                                    <br />
                                    2- Screenshot of your ‘share code’ from the government website below.
                                    <br /><br /><a href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>
                                    <br /><br /><b>You will also need to provide proof of address dated within the last 3 months. We accept Utility Bills (gas, water, electricity or phone) or Bank Letter that clearly shows your name, address, issue date and service provider.</b>

                                </Form.Label>
                            </Form.Group>
                            <Form.Group>
                                <Form.Group controlId='id1'>
                                    <Form.Label>
                                        <b>Proof of residency status</b>
                                    </Form.Label>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        required
                                        onChange={(e) => setID1(e.target.files[0])}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='id2'>
                                    <Form.Label>
                                        <b>Proof of address dated within last 3 months</b>
                                    </Form.Label>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        onChange={(e) => setID2(e.target.files[0])}
                                    ></Form.Control>
                                </Form.Group>
                            </Form.Group>
                        </>
                    )}
                    <Form.Group controlId='criminalConv'>
                        <Form.Label>
                            Do you have any criminal convictions, cautions or pending prosecutions?
                        </Form.Label>
                        <Form.Control
                            required
                            as='select'
                            value={criminalConv}
                            onChange={(e) => setCriminalConvictions(e.target.value)}
                        >
                            <option disabled selected value=''>
                                [Please select one]
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(criminalConv === "Yes") && <>
                        <div className='text-left mt-5 mb-3 form-notice'>
                            Under the Rehabilitation of Criminal Offenders Act (1974), you are required to disclose if you have any unspent criminal convictions. You will need to provide this information within 7 days of making this application by contacting the Customer Services Manager at New College by phone on 01793 732801 for a confidential discussion.
                            Please note that some courses e.g. Care, Childcare or Health Studies require you to undergo a DBS disclosure. This will include both spent and unspent convictions as
                            well as cautions, reprimands and final warnings.
                        </div>
                        <br />
                    </>}

                    {/* learn direct */}
                    {(localStorage.getItem('selectedCollege') === "676fc6eac6cf1ffc3d18417c") && <>
                        <Form.Group controlId='incare'>
                            <Form.Label>
                                In or has recently left care?
                            </Form.Label>
                            <Form.Control
                                required
                                as='select'
                                value={inCare}
                                onChange={(e) => setInCare(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {nationality !== 'british' && <>
                            <Form.Group controlId='dateyouentereduk'>
                                <Form.Label>
                                    Date you entered United Kingdom?
                                </Form.Label>
                                <Form.Control
                                    type='date'
                                    required
                                    value={dateYouEnteredUK}
                                    onChange={(e) => setDateYouEnteredUK(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {(localStorage.getItem('selectedContract') === "6775148b34f6ad497a9526b7") &&
                                <>
                                    <Form.Group controlId='hasVisa'>
                                        <Form.Label>
                                            Do you have a Visa?
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            as='select'
                                            value={hasVisa}
                                            onChange={(e) => setHasVisa(e.target.value)}
                                        >
                                            <option disabled selected value=''>
                                                [Please select one]
                                            </option>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId='rightToStudy'>
                                        <Form.Label>
                                            Have the right to study and work in the UK?
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            as='select'
                                            value={rightToStudy}
                                            onChange={(e) => setRightToStudy(e.target.value)}
                                        >
                                            <option disabled selected value=''>
                                                [Please select one]
                                            </option>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>}
                        </>}
                    </>}
                    <Button
                        onClick={(e) => {
                            window.location.href = '/qualification'
                        }}
                        variant='primary'
                        className='mr-5 back-Button-form'
                    >
                        Back
                    </Button>
                    {/* {localStorage.getItem("user") === "admin" && (
            <>
              <Button
                onClick={handleUpdate}
                style={{ backgroundColor: "green", borderColor: "green" }}
              >
                UpdateApplication
              </Button>
            </>
          )} */}
                    {/* {localStorage.getItem("user") !== "admin" && (
            <> */}
                    <Button
                        type='submit'
                        variant='primary'
                        style={{
                            backgroundColor: 'green',
                            borderColor: 'green',
                            color: 'white',
                        }}
                        className='mrt next-Button-form'
                    >
                        Save Progress & Continue
                    </Button>{' '}
                    {/* </>
          )} */}
                </Form>
            </FormContainer >
        </>
    )
}

export default EqualOppurtunities
