import axios from "axios";
import CryptoES from 'crypto-es';
import Cookies from 'js-cookie';
import moment from "moment";
const fbc = Cookies.get('_fbc');
const fbp = Cookies.get('_fbp');

export function facebookApiContact() {
    var time = Math.floor(Date.now() / 1000)
    var data = JSON.stringify({
        "data": [
            {
                "event_name": "Contact",
                "event_time": time,
                "action_source": "website",
                "user_data": {
                    "email": [
                        "83116acf18e4dc4414762f584ff43d9979ff2c2b0e9e48fbc97b21e23d7004ec"
                    ],
                    "fbc": fbc,
                    "fbp": fbp
                }
            }
        ],
        // "test_event_code": "TEST65027"
    });

    var config = {
        method: 'post',
        url: 'https://graph.facebook.com/v15.0/1149252932226423/events?access_token=EAAPJbSxwufIBAJ5t3gyG80ofFkHg8bcqujSMys7iVBxLebJipadfLtsI096lPZBz3152ZC6lzm9jvVgjtV2E8ZB5KgXsMM5ZB0FZBiB7OoLKFI08I8KkBFqlGHGzZBukZCOZCGyBNjaZBtrxfe6aEPVW7X1xjGNlxReJZAr4YSNEEs4zsLQLXX8WZCZB',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        });
}


export async function facebookApiConversation(name, phone, email, fname, lname, dob, ct, zip, st, external_id) {
    let ph = '44' + phone.substring(1)
    const hashPhone = CryptoES.SHA256(ph).toString()
    const hashEmail = CryptoES.SHA256(email).toString()
    const hashFname = CryptoES.SHA256(fname).toString()
    const hashLname = CryptoES.SHA256(lname).toString()
    const externalID = CryptoES.SHA256(external_id).toString()
    const formattedDate = moment(dob).format("YYYYMMDD");
    const hashdob = CryptoES.SHA256(formattedDate).toString()
    const hashct = CryptoES.SHA256(ct).toString()
    const hashzip = CryptoES.SHA256(zip).toString()
    const hashst = CryptoES.SHA256(st).toString()
    var time = Math.floor(Date.now() / 1000)
    const ipResponse = await axios.get('https://api.ipify.org?format=json');
    const ipAddress = ipResponse.data.ip;
    var data = JSON.stringify({
        "data": [
            {
                "event_name": name,
                "event_time": time,
                "action_source": "website",
                "user_data": {
                    "ph": [
                        hashPhone
                    ],
                    "em": [
                        hashEmail
                    ],
                    "fn": [
                        hashFname
                    ],
                    "ln": [
                        hashLname
                    ],
                    'db': hashdob,
                    'ct': hashct,
                    'st': hashst,
                    'zp': hashzip,
                    "fbc": fbc,
                    "fbp": fbp,
                    "external_id": externalID,
                    "client_ip_address": ipAddress,
                    "client_user_agent": navigator.userAgent,
                }
            }
        ],
        // "test_event_code": "TEST65027"
    });

    var config = {
        method: 'post',
        url: 'https://graph.facebook.com/v15.0/1149252932226423/events?access_token=EAAPJbSxwufIBAJ5t3gyG80ofFkHg8bcqujSMys7iVBxLebJipadfLtsI096lPZBz3152ZC6lzm9jvVgjtV2E8ZB5KgXsMM5ZB0FZBiB7OoLKFI08I8KkBFqlGHGzZBukZCOZCGyBNjaZBtrxfe6aEPVW7X1xjGNlxReJZAr4YSNEEs4zsLQLXX8WZCZB',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        });
}
