import React from "react";
import { Button, Grid, Container } from "@material-ui/core/";
import Badge from 'react-bootstrap/Badge';


const Posts = (props) => {
    const { posts } = props;


    const coursesPosts = [
        {
            "courseTitle": "Access to Higher Education Diploma in Education Professions",
            "shortDescription": "Do you have a passion for teaching? Have you considered career aspects in teaching? Do you want to help mould the next generation? If so you can take the first step today in starting your journey to your career in Education!",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Maths_Eng_gdkky2.jpg",
            'url': '/access-in-education'
        }, {
            "courseTitle": "Access to Higher Education Diploma in Health and Social Care",
            "shortDescription": "Do you have a passion for giving back to communities? Do you enjoy supporting people? Have you considered learning more about health and social care and how they play a significant role in the functionality of our societies?",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Access_to_health_and_Social_Care_ohnid2.jpg",
            'url': '/access-in-health'
        }, {
            "courseTitle": "Access to Higher Education Diploma in Health Science Professions",
            "shortDescription": "If you’re considering a healthcare career but aren’t set on which area to focus on just yet, an Access to Higher Education Diploma (Health Science Professions) is the ideal course to help you get started. It can help you study many areas of healthcare at degree level.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Access_to_Psychology_aambtu.jpg",
            'url': '/access-in-health-science'
        }, {
            "courseTitle": "Access to Higher Education Diploma in Psychology",
            "shortDescription": "Are you fascinated by people and what makes them tick? Then studying psychology will allow you to dive deep into the exploration of the mind to understand the different ways people think, feel, and behave. By looking at how different motivations and how past experiences can influence a person’s actions, reactions, and relationships.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Health_Science_Professionals_bi4n68.jpg",
            'url': '/access-in-psychology'
        }, {
            "courseTitle": "Function Skills Maths and English",
            "shortDescription": "Functional Skills are essential for success in any form of future study, qualifications and work opportunities. As a recognised alternative to GCSE qualifications, Functional Skills courses were created to offer you the most convenient solution to learn and develop practical skills in the essential subjects of maths and English.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736276457/Courses/Maths_Eng_gdkky2.jpg",
            'url': '/functional-skills-maths-english'
        }, {
            "courseTitle": "Access to Higher Education Diploma (Nursing)",
            "shortDescription": "Have you always thought about becoming a Nurse but life simply got in the way, or nursing has recently appealed to you as a career option? If so, this is the perfect course to prepare you for a Nursing degree.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736940251/Courses/dz91fpnvndep1vbkbbzo.jpg",
            'url': '/access-to-nursing'
        }, {
            "courseTitle": "Access to Higher Education Diploma (Midwifery)",
            "shortDescription": "Do you have a passion for helping bring the next generation into the world? Do you enjoy caring and supporting new mothers? Well, this course is the perfect way to help you get started into your learning journey of becoming a midwife.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736940314/Midwife_pizfry.jpg",
            'url': '/access-to-midwifery'
        }, {
            "courseTitle": "Access to Higher Education Diploma (Paramedic Science)",
            "shortDescription": "Are you driven by a desire to help others and aspire to become a paramedic? Paramedics play a crucial role in healthcare, providing life-saving care in emergencies and serving as the frontline responders during critical moments. This course is designed to give you a strong foundation in paramedic science and to enhance your study skills to support your success in higher education.",
            "courseLevel": "Level 3",
            "funding": "Funded",
            "img": "https://res.cloudinary.com/my-free-course/image/upload/v1736976334/Untitled_design_1_ew62i0.jpg",
            'url': '/access-to-paramedic-science'
        }
    ]


    if (!posts || posts.length === 0)
        return <p className="text-center">Cannot find any posts</p>;
    posts.data = posts.data.filter(x => x.status !== "disabled")
    return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {posts.data.map((post) => {
                        return (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={post.id} xs={12} md={12}>
                                <div className="card ">
                                    <div className="row">
                                        <div className="col-md-2 hidden-xs">
                                            <img
                                                style={{ objectFit: "contain" }}
                                                className="card-img-top"
                                                src={post.img}
                                                alt="Card image cap"
                                                height="300px"
                                                width="300px"
                                            />
                                        </div>
                                        <div className="col-md-8 col-xs-12">
                                            <div>
                                                <h5 className="card-title">{post.courseTitle}</h5>
                                                <p className="card-text disp ">{post.funding}</p>
                                                <p className="desc pbor">{post.shortDescription}</p>
                                                <p className="card-text  dispp">{post.funding}</p>
                                            </div>
                                        </div>

                                        <div className="col cbutton" style={{ border: "0px" }}>
                                            <Button
                                                className="co-button"
                                                onClick={(e) => {
                                                    window.location.href =
                                                        "/single?id=" + post.courseTitle;
                                                }}
                                            >
                                                View Course
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                    {coursesPosts?.map((post) => {
                        return (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={post.id} xs={12} md={12}>
                                <div className="card ">
                                    <div className="row">
                                        <div className="col-md-2 hidden-xs">
                                            <img
                                                style={{ objectFit: "contain" }}
                                                className="card-img-top"
                                                src={post.img}
                                                alt="Card image cap"
                                                height="300px"
                                                width="300px"
                                            />
                                        </div>
                                        <div className="col-md-8 col-xs-12">
                                            <div>
                                                <h5 className="card-title">{post.courseTitle}</h5>
                                                <p className="card-text disp ">{post.funding}</p>
                                                <p className="desc pbor">{post.shortDescription}</p>
                                                <p className="card-text  dispp">{post.funding}</p>
                                            </div>
                                        </div>

                                        <div className="col cbutton" style={{ border: "0px" }}>
                                            <Button
                                                className="co-button"
                                                onClick={(e) => {
                                                    window.location.href = post.url;
                                                }}
                                            >
                                                View Course
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                    {/* New static course template */}
                    {/* <Grid item xs={12} md={12}>
                        <div className="card ">
                            <div className="row">
                                <div className="col-md-2 hidden-xs">
                                    <img
                                        style={{ objectFit: "contain" }}
                                        className="card-img-top"
                                        src="https://res.cloudinary.com/my-free-course/image/upload/v1715171108/Courses/FREE_Courses_buvv14.jpg"
                                        alt="Card image cap"
                                        height="300px"
                                        width="300px"
                                    />
                                </div>
                                <div className="col-md-8 col-xs-12">
                                    <div>
                                        <h5 className="card-title">Green Skills Employability Program</h5>
                                        <p className="card-text disp ">Funded</p>
                                        <p className="desc pbor">
                                            An employability program available to those in London who are interested in gaining 2 qualifications, getting a guaranteed job interview within the green sector and receiving 1 to 1 employment support.
                                        </p>
                                        <p className="card-text  dispp">Funded</p>
                                    </div>
                                </div>

                                <div className="col cbutton" style={{ border: "0px" }}>
                                    <Button
                                        className="co-button"
                                        onClick={(e) => {
                                            window.location.href =
                                                "/employment-skills-and-climate-change"
                                        }}
                                    >
                                        View Course
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid> */}
                    {/* DEMO DATA ENDS */}


                    {/* New static Contruction course template */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665492418/Courses/Makeup_Artist_cvmycl.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Make-Up Artistry</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The primary outcome of the Level 3 Diploma in Make-up Artistry is to prepare you to enter the specific sector of beauty therapy as a make-up artist. All the units in this qualification relate directly to the specific job role of a make-up artist.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/makeup-artistry-certificate"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665489863/Courses/Bridal_Hair_yzdryg.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Bridal</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The Level 3 Diploma in Bridal Hair and Make-up will provide you with advanced practical skills and knowledge in bridal hair and make-up.

                        The diploma is aimed at people currently working in the hair and beauty sector who wish to specialise in bridal hair and make-up.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/beauty-certificate"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/v1661175867/Courses/Ixion_Beauty_Therapy_rghn9i.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Beauty Therapy Treatment</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The primary outcome of the Level 3 Diploma in Beauty Therapy Treatment is to prepare you to enter the specific sector of beauty therapy as a nail technician. All the units in this qualification relate directly to the specific job role of a beauty therapist.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/beauty-therapy-general"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* DEMO DATA ENDS */}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Posts;
